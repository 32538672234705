import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)
import index from '../pages/index/index'
import second from '../pages/second/second'
import third from '../pages/third/third'
import fouth from '../pages/fouth/fouth'



export default new VueRouter({
    routes:[
        {
            path: '/',
            redirect: '/index' //默认首页
        },
        {
            path: '/index',
            name: 'index',
            component: index
        },
        {
            path: '/second',
            name: 'second',
            component: second
        },
        {
            path: '/third',
            name: 'third',
            component: third
        },
        {
            path: '/fouth',
            name: 'fouth',
            component: fouth
        },
    ],
})