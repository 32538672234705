<template>
    <div class="container">
        <div class="headerbar">
            <div class="logo">
                <img src="../../img/logo.svg" alt="">
                <img src="../../img/logo2.png" alt="">
            </div>
            <div class="zhongjian">
                <p>数算一体创新中心</p>
            </div>
        </div>
        <div class="zhong">
            <div class="zhong1">
                <div class="tag">
                    <p>算法引擎库</p>
                    <img src="../../img/d.png" alt="">
                </div>
                <div class="ff">
                    <div class="ff2">
                        <p>预训练</p>
                        <p>算法</p>
                    </div>
                    <div class="ff1">
                        <p>10</p>
                        <span>个</span>
                    </div>
                </div>
                <div class="ff">
                    <div class="ff2">
                        <p>调优</p>
                        <p>算法</p>
                    </div>
                    <div class="ff1">
                        <p>19</p>
                        <span>个</span>
                    </div>
                </div>
                <div class="ff">
                    <div class="ff2">
                        <p>推理</p>
                        <p>算法</p>
                    </div>
                    <div class="ff1">
                        <p>21</p>
                        <span>个</span>
                    </div>
                </div>
            </div>
            <div class="zhong1">
                <div class="tag">
                    <p>语料库</p>
                    <img src="../../img/d.png" alt="">
                </div>
                <div class="ff">
                    <div class="ff2">
                        <p>训练使用</p>
                        <p>通用语料</p>
                    </div>
                    <div class="ff1">
                        <p>780</p>
                        <span>TB</span>
                    </div>
                </div>
                <div class="ff">
                    <div class="ff2">
                        <p>训练使用</p>
                        <p>领域语料</p>
                    </div>
                    <div class="ff1">
                        <p>230</p>
                        <span>TB</span>
                    </div>
                </div>
                <div class="ff">
                    <div class="ff2">
                        <p>推理使用</p>
                        <p>通用语料</p>
                    </div>
                    <div class="ff1">
                        <p>209</p>
                        <span>TB</span>
                    </div>
                </div>
                <div class="ff">
                    <div class="ff2">
                        <p>推理使用</p>
                        <p>领域语料</p>
                    </div>
                    <div class="ff1">
                        <p>236</p>
                        <span>TB</span>
                    </div>
                </div>
            </div>
            <div class="zhong1">
                <div class="tag">
                    <p>大模型库</p>
                    <img src="../../img/d.png" alt="">
                </div>
                <div class="ff">
                    <div class="ff2">
                        <p>开源</p>
                        <p>大模型</p>
                    </div>
                    <div class="ff1">
                        <p>8</p>
                        <span>个</span>
                    </div>
                </div>
                <div class="ff">
                    <div class="ff2">
                        <p>领域</p>
                        <p>大模型</p>
                    </div>
                    <div class="ff1">
                        <p>3</p>
                        <span>个</span>
                    </div>
                </div>
                <div class="ff">
                    <div class="ff2">
                        <p>企业</p>
                        <p>大模型</p>
                    </div>
                    <div class="ff1">
                        <p>11</p>
                        <span>个</span>
                    </div>
                </div>

            </div>
        </div>
        <div class="content">
            <div class="foutLeft">
                <div class="left1">
                    <div class="left1Top">
                        <div class="left1Top1">
                            <img src="../../img/biaozhi.png" alt="">
                            <p>数据资源地图</p>
                        </div>
                        <div class="more">
                            <img src="../../img/more.png" alt="">
                        </div>
                    </div>
                    <div class="left1Con">
                        <div class="listTab">
                            <ul>
                                <li>
                                    <p>资源名称</p>
                                </li>
                                <li>
                                    <p>调用方</p>
                                </li>
                                <li>
                                    <p>调用时间</p>
                                </li>
                            </ul>
                        </div>
                        <vue-seamless-scroll :data="slist" class="seamless-warp" :class-option="defaultOption">
                            <div class="list_c">
                                <div class="c" v-for="(item, index) in slist" :key="index">
                                    <ul>
                                        <li>
                                            <p>{{ item.name }}</p>
                                        </li>
                                        <li>
                                            <p>{{ item.cc }}</p>
                                        </li>
                                        <li>
                                            <p>{{ item.date }}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </vue-seamless-scroll>
                    </div>
                </div>
                <div class="left2">
                    <div class="left1Top">
                        <div class="left1Top1">
                            <img src="../../img/biaozhi.png" alt="">
                            <p>算网能力</p>
                        </div>
                        <div class="more">
                            <img src="../../img/more.png" alt="">
                        </div>
                    </div>
                    <div class="left2Con">
                        <div class="left2Con1">
                            <div class="left21">
                                <div class="text">
                                    <p>超</p>
                                    <span>8.4</span>
                                    <p>万个</p>
                                </div>
                                <div class="pp">分布式协同计算点</div>
                            </div>
                            <div class="left21">
                                <div class="text">
                                    <span>47.8</span>
                                    <p>万架</p>
                                </div>
                                <div class="pp">对外可用IDC机架</div>
                            </div>
                            <div class="left21">
                                <div class="text">
                                    <p>超</p>
                                    <span>80.4</span>
                                    <p>万台</p>
                                </div>
                                <div class="pp">云服务器</div>
                            </div>
                        </div>
                        <div class="left2Con2">
                            <div class="quan">
                                <div id="quan4"></div>
                                <div class="des">
                                    <p>可用宽带</p>
                                </div>
                            </div>
                            <div class="quan">
                                <div id="quan5"></div>
                                <div class="des">
                                    <p>带宽利用率</p>
                                </div>
                            </div>
                            <div class="quan">
                                <div id="quan6"></div>
                                <div class="des">
                                    <p>带宽峰值</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="left3">
                    <div class="left1Top">
                        <div class="left1Top1">
                            <img src="../../img/biaozhi.png" alt="">
                            <p>大模型应用行业分布</p>
                        </div>
                        <div class="more">
                            <img src="../../img/more.png" alt="">
                        </div>
                    </div>
                    <div class="left3Con">
                        <div id="echarts4"></div>
                    </div>
                </div>
            </div>
            <div class="foutRight">
                <img src="../../img/liucheng.svg" alt="">
            </div>
        </div>
        <router-link to="/third" class="zuozuo">
            <img src="../../img/xiangzuo.png" alt="">
        </router-link>
        <router-link to="/index" class="youyou">
            <img src="../../img/xiangyou.png" alt="">
        </router-link>
    </div>
</template>

<script>
const echarts = require("echarts");
import 'echarts-liquidfill'
export default {
    data() {
        return {
            slist: [
                {
                    name: '目标区域圈选数据',
                    cc: '杭州金融机构局',
                    date: '2024-01-05'
                },
                {
                    name: '市场预测数据',
                    cc: '工业制造机构',
                    date: '2024-02-08'
                },
                {
                    name: '历史客流数据趋势',
                    cc: '杭州某高校',
                    date: '2024-02-23'
                },
                {
                    name: '形势研判数据',
                    cc: '杭州某银行',
                    date: '2024-03-15'
                },
                {
                    name: '目标区域圈选数据',
                    cc: '杭州金融机构局',
                    date: '2024-03-17'
                },
                {
                    name: '市场预测数据',
                    cc: '工业制造机构',
                    date: '2024-03-22'
                },
            ],
            shuidata: {
                value: '45Mbps',
                label: "可用宽带",
                color: "#6CC7F0",
                color1: "#6CC7F0",
            },
            shuidata1: {
                value: '40%',
                label: "带宽利用率",
                color: "#AB5DEF",
                color1: "#AB5DEF",
            },
            shuidata2: {
                value: '37Mbps',
                label: "带宽峰值",
                color: "#84DBE6",
                color1: "#84DBE6"
            },
            progress: {
                num: 22.6
            },
            hangye: [
                {
                    name: '金融行业',
                    value: '212',
                    itemStyle: {
                        color: '#EE567F'
                    },
                },
                {
                    name: '传媒行业',
                    value: '314',
                    itemStyle: {
                        color: '#8180C9'
                    }
                },
                {
                    name: '教育行业',
                    value: '412',
                    itemStyle: {
                        color: '#5662EE',
                    }
                },
                {
                    name: '其他',
                    value: '157',
                    itemStyle: {
                        color: '#24E8FF',
                    }
                },
                {
                    name: '电力行业',
                    value: '226',
                    itemStyle: {
                        color: '#E5714C',
                    }
                },
                {
                    name: '通信行业',
                    value: '354',
                    itemStyle: {
                        color: '#E5C545',
                    }
                },
                {
                    name: '工业制造',
                    value: '133',
                    itemStyle: {
                        color: '#23EBC0',
                    }
                },
            ],
            hangye1: [
                {
                    name: '金融行业',
                    value: '200',
                    itemStyle: {
                        color: 'rgba(238, 86, 127, 0.3)'
                    }
                },
                {
                    name: '传媒行业',
                    value: '330',
                    itemStyle: {
                        color: 'rgba(129, 128, 201,0.3)'
                    }
                },
                {
                    name: '教育行业',
                    value: '400',
                    itemStyle: {
                        color: 'rgba(86, 98, 238,0.3)',
                    }
                },
                {
                    name: '其他',
                    value: '150',
                    itemStyle: {
                        color: 'rgba(36, 232, 255,0.3)',
                    }
                },
                {
                    name: '电力行业',
                    value: '200',
                    itemStyle: {
                        color: 'rgba(229, 113, 76,0.3)',
                    }
                },
                {
                    name: '通信行业',
                    value: '350',
                    itemStyle: {
                        color: 'rgba(229, 197, 69,0.3)',
                    }
                },
                {
                    name: '工业制造',
                    value: '130',
                    itemStyle: {
                        color: 'rgba(35, 235, 192,0.3)',
                    }
                },
            ],
        }
    },
    computed: {
        defaultOption() {
            return {
                step: 0.3, // 数值越大速度滚动越快
                limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            }
        }
    },
    mounted() {
        this.getshui2(this.shuidata, "quan4")
        this.getshui2(this.shuidata1, "quan5")
        this.getshui2(this.shuidata2, "quan6")
        this.getecharts4(this.hangye, 'echarts4')
    },
    methods: {
        getshui2(data, id) {
            // var _this = this;
            this.secChart4 = echarts.init(document.getElementById(id));
            this.secChart4.setOption({
                // backgroundColor: "#050038",
                title: {
                    text: ``,
                    left: "center",
                    top: "35",
                    textStyle: {
                        show: false,
                        fontWeight: "normal",
                        fontSize: 20,
                        color: "#fff"
                    },
                    // textAlign:'center',
                    subtext: data.value, //副标题
                    subtextStyle: {
                        fontWeight: "bold",
                        fontSize: 18,
                        color: "#fff",
                        fontFamily: "pmzd"

                    }
                },

                series: [{
                    type: "liquidFill", // 图表类型
                    radius: "80%",
                    center: ["50%", "50%"],
                    data: [
                        this.progress.num / 100,
                        this.progress.num / 100,
                        this.progress.num / 100
                    ], // data个数代表波浪数
                    backgroundStyle: {
                        borderWidth: 1,
                        color: "transparent"
                    },
                    label: {
                        normal: {
                            show: false, //不显示label  用副标题代替了
                            formatter: (0.5 * 100).toFixed(0) + "%",
                            textStyle: {
                                fontSize: 38,
                                color: "black"
                            }
                        }
                    },
                    color: [ //color一定要是个数组 ，每一项对应一个波浪，可以给每个波浪单独配置颜色
                        {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 1,
                                color: data.color
                            },
                            {
                                offset: 0,
                                color: data.color
                            }
                            ],
                            globalCoord: false
                        },
                        {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 1,
                                color: data.color
                            },
                            {
                                offset: 0,
                                color: data.color
                            }
                            ],
                            globalCoord: false
                        },
                        {
                            type: "linear",
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 1,
                                color: data.color
                            },
                            {
                                offset: 0,
                                color: data.color
                            }
                            ],
                            globalCoord: false
                        }
                    ],
                    outline: {
                        show: false
                    }
                },
                //外层线
                {
                    type: "pie",
                    center: ["50%", "50%"],
                    radius: ["82%", "83%"], //外层线粗细
                    hoverAnimation: false,
                    data: [{
                        name: "",
                        value: 100,
                        labelLine: {
                            show: false
                        },
                        itemStyle: {
                            color: "#33E2ED"
                        },
                        emphasis: {
                            labelLine: {
                                show: false
                            },
                            itemStyle: {
                                // color: "#018ab9"
                            }
                        }
                    }]
                },
                //进度线
                {
                    type: "pie",
                    center: ["50%", "50%"],
                    radius: ["80%", "85%"], //进度线粗细
                    hoverAnimation: false,
                    data: [{
                        name: "",
                        value: this.progress.num, //进度
                        labelLine: {
                            show: false
                        },
                        itemStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 1,
                                y2: 2,
                                colorStops: [
                                    // !! 在此添加渐变过程色 !!
                                    {
                                        offset: 0,
                                        color: "#33E2ED"
                                    },
                                    {
                                        offset: 1,
                                        color: "#33E2ED"
                                    }
                                ]
                            }
                        },
                        emphasis: {
                            labelLine: {
                                show: false
                            },
                            itemStyle: {
                                color: "#33E2ED"
                            }
                        }
                    },
                    {
                        //画剩余的刻度圆环
                        name: "",
                        value: 100 - this.progress.num,
                        itemStyle: {
                            color: "#33E2ED"
                        },
                        label: {
                            show: false
                        },
                        labelLine: {
                            show: false
                        },
                        emphasis: {
                            labelLine: {
                                show: false
                            },
                            itemStyle: {
                                color: "#33E2ED"
                            }
                        }
                    }
                    ]
                }
                ]
            });
        },
        getecharts4(data, id) {
            this.myChart = echarts.init(document.getElementById(id));
            var option = {
                tooltip: {
                    show: true,
                    trigger: "item",
                    axisPointer: {
                        type: "shadow",
                        shadowStyle: {
                            color: "rgba(0,156,255,0.2)"
                        },
                        z: 1,
                    },
                    padding: [15, 22],
                    backgroundColor: "rgba(0,0,0,0.9)",
                    borderColor: "#01a3ce",
                    borderWidth: 1,
                    textStyle: {
                        fontSize: 16,
                        lineHeight: 32,
                        color: "#ffffff"
                    },
                },
                legend: {
                    top: '5%',
                    left: 'center',
                    show: false,
                },
                series: [
                    {
                        name: '行业',
                        type: 'pie',
                        z: 10,
                        radius: ['40%', '60%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 0,
                            borderColor: '#fff',
                            borderWidth: 0
                        },
                        label: {
                            show: true,
                            // position: 'center'
                            textStyle: {
                                color: '#fff',
                                fontSize: '13'
                            }
                        },
                        emphasis: {
                            // label: {
                            //   show: true,
                            //   fontSize: 40,
                            //   fontWeight: 'bold'
                            // }
                        },
                        labelLine: {
                            show: true
                        },
                        data: data
                    },
                    {
                        name: '',
                        type: 'pie',
                        tooltip: {
                            show: false
                        },
                        z: 8,
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 0,
                            borderColor: '#fff',
                            borderWidth: 0
                        },
                        label: {
                            show: false,
                            // position: 'center'
                            textStyle: {
                                color: '#fff',
                                fontSize: '13'
                            }
                        },
                        emphasis: {
                            // label: {
                            //   show: true,
                            //   fontSize: 40,
                            //   fontWeight: 'bold'
                            // }
                        },
                        labelLine: {
                            show: false
                        },
                        data: this.hangye1
                    }
                ]
            };
            this.myChart.setOption(option);
        },
    },
}
</script>

<style scoped lang="scss">
.container {
    position: relative;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 0 1%;
    background: url(../../img/bg.png) center no-repeat;
    background-size: 100% 100%;

    .headerbar {
        margin-bottom: calc(100vh * 20 / 1080);
        width: 100%;
        height: calc(100vh * 100 / 1080);
        background: url(../../img/head_bg.png) center no-repeat;
        background-size: 100% 100%;
        position: relative;
        z-index: 99999;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
      position: absolute;
      left: 0;
      z-index: 999;
      width: auto;
      height: auto;
      padding-left: 12px;

      img {
        width: auto;
        max-height: 40px;
        margin-right: 12px;
      }
    }

        .zhongjian {
            width: auto;
            height: auto;
            text-align: center;

            p {
                font-size: 2em;
                color: #fff;
            }
        }
    }

    .zhong {
        position: relative;
        z-index: 99999;
        margin-bottom: calc(100vh * 20 / 1080);
        width: 100%;
        height: calc(100vh * 80 / 1080);
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;

        .zhong1 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: auto;
            height: calc(100vh * 80 / 1080);
            background: #03245B;
            box-sizing: border-box;
            padding: 1%;
            width: 25%;

            &:nth-child(2) {
                margin: 0 1%;
                width: 42%;
            }

            &:nth-child(3) {
                width: 30%;
            }

            .tag {
                width: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                p {
                    margin-bottom: -15px;
                    position: relative;
                    z-index: 99;
                    font-size: 0.9rem;
                    color: #fff;
                }

                img {
                    position: relative;
                    z-index: 2;
                }
            }

            .ff {
                margin: 0 1%;
                width: auto;
                display: flex;
                align-items: center;
                width: 200px;

                .ff2 {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 55%;

                    p {
                        font-size: 1rem;
                        color: #fff;
                        white-space: nowrap;
                    }
                }

                .ff1 {
                    display: flex;
                    align-items: center;

                    p {
                        font-weight: bold;
                        font-size: 1.7rem;
                        color: #00B3FF;
                    }

                    span {
                        font-size: 1.1rem;
                        color: #00B3FF;
                    }
                }
            }
        }
    }

    .content {
        position: relative;
        width: 100%;
        height: calc(100vh * 860 / 1080);
        display: flex;
        justify-content: space-between;

        .left1Top {
            width: 100%;
            height: calc(100vh * 40 / 1080);
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: url(../../img/top_bg.png) center no-repeat;
            background-size: 100% 100%;
            box-sizing: border-box;
            padding: 0 3%;

            .left1Top1 {
                width: auto;
                height: auto;
                display: flex;
                align-items: center;

                img {
                    width: auto;
                    margin-right: 10px;
                }

                p {
                    font-size: 1.2rem !important;
                    color: #fff;
                }
            }

            .more {
                width: auto;

                img {
                    width: auto;
                }
            }
        }

        .foutLeft {
            position: relative;
            z-index: 9999;
            width: 21%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
            padding-bottom: 1%;

            .left1 {
                margin-bottom: calc(100vh * 10 / 1080);
                width: 100%;
                height: calc(100vh * 276 / 1080);

                .left1Con {
                    width: 100%;
                    height: calc(100vh * 230 / 1080);
                    background: #011738;

                    .listTab {
                        width: 100%;
                        height: calc(100vh * 33 / 1080);
                        background: #072451;

                        ul {
                            box-sizing: border-box;
                            padding: 0 3%;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            li {
                                width: 30%;
                                height: auto;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                p {
                                    font-size: 0.8rem;
                                    color: #fff;
                                }
                            }
                        }
                    }

                    .seamless-warp {
                        width: 100%;
                        height: calc(100vh * 200 / 1080);
                        box-sizing: border-box;
                        padding: 0 3%;
                        overflow: hidden;
                        position: relative;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .list_c {
                            width: 100%;
                            height: calc(100vh * 200 / 1080);

                            .c {
                                margin-bottom: calc(100vh * 10 / 1080);
                                width: 100%;
                                height: calc(100vh * 30 / 1080);

                                ul {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;

                                    li {
                                        width: 30%;
                                        height: auto;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        display: flex;
                                        align-items: center;
                                        justify-content: centers;

                                        &:nth-child(2) {
                                            color: #fff;
                                        }

                                        p {
                                            font-size: 0.8rem;
                                            color: #35D4FF;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .left2 {
                margin-bottom: calc(100vh * 10 / 1080);
                width: 100%;
                height: calc(100vh * 276 / 1080);
                background: #01154F;

                .left2Con1 {
                    width: 100%;
                    height: calc(100vh * 90 / 1080);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 0 5%;
                    background: #01154F;

                    .left21 {
                        width: 25%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                        background: url(../../img/secbg1.png) center no-repeat;
                        background-size: 100% 100%;
                        box-sizing: border-box;
                        padding-top: 3%;

                        .text {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-bottom: 5%;

                            p {
                                font-size: 0.7rem;
                                color: rgba($color: #fff, $alpha: 0.7);
                            }

                            span {
                                margin: 0 0.1rem;
                                color: #fff;
                                font-size: 1rem;
                            }
                        }

                        .pp {
                            font-size: 0.6rem;
                            color: rgba($color: #fff, $alpha: 0.7);
                        }
                    }
                }

                .left2Con2 {
                    width: 100%;
                    height: calc(100vh * 140 / 1080);
                    background: #01154F;
                    box-sizing: border-box;
                    padding: 0 3%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .quan {
                        width: 31%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background: url(../../img/quanBg.png) center calc(100vh * -15 / 1080) no-repeat;
                        background-size: 100% 100%;

                        #quan4 {
                            width: 100%;
                            height: calc(100vh * 100 / 1080);
                        }

                        #quan5 {
                            width: 100%;
                            height: calc(100vh * 100 / 1080);
                        }

                        #quan6 {
                            width: 100%;
                            height: calc(100vh * 100 / 1080);
                        }

                        .des {
                            margin-top: calc(100vh * 10 / 1080);
                            width: 90px;
                            height: 25px;
                            background: url(../../img/secbg4.png) center no-repeat;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            justify-content: center;

                            p {
                                font-size: 0.7rem;
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .left3{
                width: 100%;
                height: calc(100vh * 270 / 1080);
                
                .left3Con{
                    width: 100%;
                    height: calc(100vh * 230 / 1080);
                    background: url(../../img/bg2.png) center no-repeat;
                    background-size: 100% 100%;
                    #echarts4{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .foutRight{
            width: 77%;
            height: 100%;
            img{
                width: 98%;
                height: 98%;
                object-fit: contain;
            }
        }
    }
}
</style>