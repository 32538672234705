<template>
  <div class="container">
    <!-- 头部 -->
    <div class="headerbar">
      <div class="logo">
        <img src="../../img/logo.svg" alt="">
        <img src="../../img/logo2.png" alt="">
      </div>
      <div class="zhongjian">
        <p>数据资源地图</p>
      </div>
    </div>
    <!-- 选择横条 -->
    <div class="xuanze">
      <div class="xz1">
        <p>分类标准:</p>
        <div class="zlist">
          <el-select v-model="value" placeholder="内置金融行业数据分类">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="xz1">
        <p>数据分类:</p>
        <div class="zlist">
          <el-select v-model="value1" placeholder="请选择">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="xz1">
        <p>敏感分级:</p>
        <div class="zlist">
          <el-select v-model="value2" placeholder="请选择">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="xz1">
        <p>接入方式:</p>
        <div class="zlist">
          <el-select v-model="value3" placeholder="请选择">
            <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="xz1">
        <p>资源名称:</p>
        <div class="zlist">
          <el-select v-model="value4" placeholder="请选择">
            <el-option v-for="item in options4" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="xz2">
        <img src="../../img/jiahao.png" alt="">
      </div>
      <div class="xz2">
        <img src="../../img/caidan.png" alt="">
      </div>
    </div>
    <!-- 整体内容部分 -->
    <div class="content">
      <!-- 左边内容 -->
      <div class="conLeft">
        <!-- 第一个展示 -->
        <div class="left1">
          <div class="left1Top">
            <div class="left1Top1">
              <img src="../../img/biaozhi.png" alt="">
              <p>合作伙伴数(个)</p>
            </div>
            <div class="more">
              <img src="../../img/more.png" alt="">
            </div>
          </div>
          <div class="left1Con">
            <div class="left1Con1">
              <img src="../../img/icon1.png" alt="">
              <p>150</p>
            </div>
            <div class="left1Con2">
              <div class="list1">
                <span>同比</span>
                <p>89%</p>
              </div>
              <div class="list1">
                <span>环比</span>
                <p>14%</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 第二个展示 -->
        <div class="left1">
          <div class="left1Top">
            <div class="left1Top1">
              <img src="../../img/biaozhi.png" alt="">
              <p>数据资源总量(个)</p>
            </div>
            <div class="more">
              <img src="../../img/more.png" alt="">
            </div>
          </div>
          <div class="left1Con">
            <div class="left1Con1">
              <img src="../../img/icon1.png" alt="">
              <p>5262</p>
            </div>
            <div class="left1Con2">
              <div class="list1">
                <span>同比</span>
                <p>151%</p>
              </div>
              <div class="list1">
                <span>环比</span>
                <p>26%</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 第三个展示 -->
        <div class="left2">
          <div class="left1Top">
            <div class="left1Top1">
              <img src="../../img/biaozhi.png" alt="">
              <p>金融行业分布</p>
            </div>
            <div class="more">
              <img src="../../img/more.png" alt="">
            </div>
          </div>
          <div class="left2Con">
            <div id="echarts"></div>
          </div>
        </div>
        <!-- 第四个展示 -->
        <div class="left2">
          <div class="left1Top">
            <div class="left1Top1">
              <img src="../../img/biaozhi.png" alt="">
              <p>数据类型分布</p>
            </div>
            <div class="more">
              <img src="../../img/more.png" alt="">
            </div>
          </div>
          <div class="left2Con">
            <div id="echarts1"></div>
          </div>
        </div>
      </div>
      <!-- 中间内容 -->
      <div class="conMid">
        <div class="midTop">
          <el-input placeholder="请输入关键词搜索，例如“工控设备参数”" v-model="input3" class="input-with-select">
            <el-button slot="append" icon="el-icon-search">搜索</el-button>
          </el-input>
        </div>
        <div class="midC">
          <div class="fufu">
            <img src="../../img/qiu.svg" alt="">
          </div>
        </div>
      </div>
      <!-- 右边内容 -->
      <div class="conRight">
        <div class="right1">
          <div class="left1Top">
            <div class="left1Top1">
              <img src="../../img/biaozhi.png" alt="">
              <p>数据资源月度访问TOP5</p>
            </div>
            <div class="more">
              <img src="../../img/more.png" alt="">
            </div>
          </div>
          <div class="right1Con">
            <div class="list1">
              <p>金融反欺诈</p>
              <el-progress :percentage="98" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor"></el-progress>
              <span>66次</span>
            </div>
            <div class="list1">
              <p>信用评分</p>
              <el-progress :percentage="80" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor1"></el-progress>
              <span>61次</span>
            </div>
            <div class="list1">
              <p>营销接受度</p>
              <el-progress :percentage="60" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor2"></el-progress>
              <span>55次</span>
            </div>
            <div class="list1">
              <p>用户真实性</p>
              <el-progress :percentage="50" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor3"></el-progress>
              <span>31次</span>
            </div>
            <div class="list1">
              <p>区域画像</p>
              <el-progress :percentage="30" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor4"></el-progress>
              <span>22次</span>
            </div>
          </div>
        </div>
        <div class="right1">
          <div class="left1Top">
            <div class="left1Top1">
              <img src="../../img/biaozhi.png" alt="">
              <p>行业月度访问TOP5</p>
            </div>
            <div class="more">
              <img src="../../img/more.png" alt="">
            </div>
          </div>
          <div class="right1Con">
            <div class="list1">
              <p>金融行业</p>
              <el-progress :percentage="80" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor"></el-progress>
              <span>69次</span>
            </div>
            <div class="list1">
              <p>通信行业</p>
              <el-progress :percentage="70" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor1"></el-progress>
              <span>54次</span>
            </div>
            <div class="list1">
              <p>工业制造</p>
              <el-progress :percentage="50" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor2"></el-progress>
              <span>45次</span>
            </div>
            <div class="list1">
              <p>公共数据</p>
              <el-progress :percentage="40" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor3"></el-progress>
              <span>34次</span>
            </div>
            <div class="list1">
              <p>能源行业</p>
              <el-progress :percentage="20" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor4"></el-progress>
              <span>19次</span>
            </div>
          </div>
        </div>
        <div class="right1">
          <div class="left1Top">
            <div class="left1Top1">
              <img src="../../img/biaozhi.png" alt="">
              <p>数据供应商月度访问TOP5</p>
            </div>
            <div class="more">
              <img src="../../img/more.png" alt="">
            </div>
          </div>
          <div class="right1Con">
            <div class="list1">
              <p>中国移动</p>
              <el-progress :percentage="80" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor"></el-progress>
              <span>65次</span>
            </div>
            <div class="list1">
              <p>浙江电力</p>
              <el-progress :percentage="60" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor1"></el-progress>
              <span>49次</span>
            </div>
            <div class="list1">
              <p>杭州大数据</p>
              <el-progress :percentage="50" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor2"></el-progress>
              <span>41次</span>
            </div>
            <div class="list1">
              <p>中航信</p>
              <el-progress :percentage="40" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor3"></el-progress>
              <span>23次</span>
            </div>
            <div class="list1">
              <p>同花顺</p>
              <el-progress :percentage="20" :stroke-width="10" :show-text="false" :define-back-color="bgcolor"
                :color="customColor4"></el-progress>
              <span>15次</span>
            </div>
          </div>
        </div>
      </div>




    </div>

    <router-link to="/fouth" class="zuozuo">
      <img src="../../img/xiangzuo.png" alt="">
    </router-link>
    <router-link to="/second" class="youyou">
      <img src="../../img/xiangyou.png" alt="">
    </router-link>



  </div>
</template>

<script>
const echarts = require("echarts");
// import $ from 'jquery'
import "echarts-gl";
export default {
  data() {
    return {
      myChart: "",
      myChart1: "",
      myChart2: "",
      customColor: '#FF7224',
      customColor1: '#EACA8F',
      customColor2: '#DC81B6',
      customColor3: '#06B0BB',
      customColor4: '#657FE2',
      bgcolor: '#041737',
      hangye: [
        {
          name: '金融行业',
          value: '212',
          itemStyle: {
            color: '#EE567F'
          },
        },
        {
          name: '传媒行业',
          value: '313',
          itemStyle: {
            color: '#8180C9'
          }
        },
        {
          name: '教育行业',
          value: '414',
          itemStyle: {
            color: '#5662EE',
          }
        },
        {
          name: '其他',
          value: '156',
          itemStyle: {
            color: '#24E8FF',
          }
        },
        {
          name: '电力行业',
          value: '189',
          itemStyle: {
            color: '#E5714C',
          }
        },
        {
          name: '通信行业',
          value: '313',
          itemStyle: {
            color: '#E5C545',
          }
        },
        {
          name: '工业制造',
          value: '137',
          itemStyle: {
            color: '#23EBC0',
          }
        },
      ],
      hangye1: [
        {
          name: '金融行业',
          value: '223',
          itemStyle: {
            color: 'rgba(238, 86, 127, 0.3)'
          }
        },
        {
          name: '传媒行业',
          value: '313',
          itemStyle: {
            color: 'rgba(129, 128, 201,0.3)'
          }
        },
        {
          name: '教育行业',
          value: '412',
          itemStyle: {
            color: 'rgba(86, 98, 238,0.3)',
          }
        },
        {
          name: '其他',
          value: '156',
          itemStyle: {
            color: 'rgba(36, 232, 255,0.3)',
          }
        },
        {
          name: '电力行业',
          value: '124',
          itemStyle: {
            color: 'rgba(229, 113, 76,0.3)',
          }
        },
        {
          name: '通信行业',
          value: '323',
          itemStyle: {
            color: 'rgba(229, 197, 69,0.3)',
          }
        },
        {
          name: '工业制造',
          value: '133',
          itemStyle: {
            color: 'rgba(35, 235, 192,0.3)',
          }
        },
      ],
      leixing: [
        {
          name: '数据集',
          value: '315',
          itemStyle: {
            color: '#8180C9'
          }
        },
        {
          name: '模型服务',
          value: '211',
          itemStyle: {
            color: '#EE567F'
          }
        },
        {
          name: 'SaaS',
          value: '153',
          itemStyle: {
            color: '#24E8FF'
          }
        },
        {
          name: '数据api',
          value: '313',
          itemStyle: {
            color: '#FF7224'
          }
        },
        {
          name: '数据报告',
          value: '252',
          itemStyle: {
            color: '#E5C545'
          }
        },
        {
          name: '其他',
          value: '412',
          itemStyle: {
            color: '#23EBC0'
          }
        },
      ],
      leixing1: [
        {
          name: '数据集',
          value: '312',
          itemStyle: {
            color: 'rgba(129, 128, 201,0.3)'
          }
        },
        {
          name: '模型服务',
          value: '246',
          itemStyle: {
            color: 'rgba(238, 86, 127,0.3)'
          }
        },
        {
          name: 'SaaS',
          value: '136',
          itemStyle: {
            color: 'rgba(36, 232, 255,0.3)'
          }
        },
        {
          name: '数据api',
          value: '312',
          itemStyle: {
            color: 'rgba(255, 114, 36,0.3)'
          }
        },
        {
          name: '数据报告',
          value: '252',
          itemStyle: {
            color: 'rgba(229, 197, 69,0.3)'
          }
        },
        {
          name: '其他',
          value: '419',
          itemStyle: {
            color: 'rgba(35, 235, 192,0.3)'
          }
        },
      ],
      options: [{
        value: '选项1',
        label: '内置金融行业数据分级分类标准'
      }, {
        value: '选项2',
        label: '通信行业数据分级分类标准'
      }, {
        value: '选项3',
        label: '公共数据分级分类标准'
      }],
      options1: [{
        value: '选项1',
        label: '纺织服饰'
      }, {
        value: '选项2',
        label: '汽车智造'
      }, {
        value: '选项3',
        label: '金融'
      }, {
        value: '选项4',
        label: '通信'
      }, {
        value: '选项5',
        label: '公共数据'
      },{
        value: '选项6',
        label: '其他'
      }],
      options2: [{
        value: '选项1',
        label: 'L1'
      }, {
        value: '选项2',
        label: 'L2'
      }, {
        value: '选项3',
        label: 'L3'
      }, {
        value: '选项4',
        label: 'L4'
      }, {
        value: '选项5',
        label: 'L5'
      }],
      options3: [{
        value: '选项1',
        label: 'TXT'
      }, {
        value: '选项2',
        label: 'CSV'
      }, {
        value: '选项3',
        label: 'MYSQ'
      }, {
        value: '选项4',
        label: '其他'
      }],
      options4: [{
        value: '选项1',
        label: '反欺诈'
      }, {
        value: '选项2',
        label: '工业'
      }, {
        value: '选项3',
        label: '营销'
      }, {
        value: '选项4',
        label: '汽车'
      }, {
        value: '选项5',
        label: '研发'
      }, {
        value: '选项6',
        label: '其他'
      }],
      value: '',
      value1: '',
      value2: '',
      value3: '',
      value4: '',
      input3: '',
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.getecharts(this.hangye, 'echarts')
    this.getecharts1(this.leixing, 'echarts1')
    this.getfu()
  },
  methods: {
    getecharts(data, id) {
      this.myChart = echarts.init(document.getElementById(id));
      var option = {
        tooltip: {
          show: true,
          trigger: "item",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0,156,255,0.2)"
            },
            z: 1,
          },
          padding: [15, 22],
          backgroundColor: "rgba(0,0,0,0.9)",
          borderColor: "#01a3ce",
          borderWidth: 1,
          textStyle: {
            fontSize: 16,
            lineHeight: 32,
            color: "#ffffff"
          },
        },
        legend: {
          top: '5%',
          left: 'center',
          show: false,
        },
        series: [
          {
            name: '行业',
            type: 'pie',
            z: 10,
            radius: ['40%', '60%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 0
            },
            label: {
              show: true,
              // position: 'center'
              textStyle: {
                color: '#fff',
                fontSize: '13'
              }
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: true
            },
            data: data
          },
          {
            name: '',
            type: 'pie',
            tooltip: {
              show: false
            },
            z: 8,
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 0
            },
            label: {
              show: false,
              // position: 'center'
              textStyle: {
                color: '#fff',
                fontSize: '13'
              }
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: false
            },
            data: this.hangye1
          }
        ]
      };
      this.myChart.setOption(option);
    },
    getecharts1(data, id) {
      this.myChart1 = echarts.init(document.getElementById(id));
      var option1 = {
        tooltip: {
          show: true,
          trigger: "item",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0,156,255,0.2)"
            },
            z: 1,
          },
          padding: [15, 22],
          backgroundColor: "rgba(0,0,0,0.9)",
          borderColor: "#01a3ce",
          borderWidth: 1,
          textStyle: {
            fontSize: 16,
            lineHeight: 32,
            color: "#ffffff"
          },
        },
        legend: {
          top: '5%',
          left: 'center',
          show: false,
        },

        series: [
          {
            name: '类型',
            type: 'pie',
            z: 10,
            radius: ['40%', '60%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#041736',
              borderWidth: 5
            },
            label: {
              show: true,
              // position: 'center'
              textStyle: {
                color: '#fff',
                fontSize: '13'
              }
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: true
            },
            data: data
          },
          {
            name: '类型',
            type: 'pie',
            tooltip: {
              show: false,
            },
            z: 8,
            radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#041736',
              borderWidth: 0
            },
            label: {
              show: false,
              // position: 'center'
              textStyle: {
                color: '#fff',
                fontSize: '13'
              }
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: false
            },
            data: this.leixing1
          }
        ]
      };
      this.myChart1.setOption(option1);
    },
    getfu() {
      this.myChart2 = echarts.init(document.getElementById('fufu'));
      // var ROOT_PATH =
      //   "https://cdn.jsdelivr.net/gh/apache/echarts-website@asf-site/examples";
      // var dom = myChart.getDom();
      // myChart.dispose();
      // myChart = echarts.init(dom);
      // $.getJSON(ROOT_PATH + "/data-gl/asset/data/npmdep.json", function (data) {
      // var data = {
      //   "nodes": [
      //     "A",
      //     "B",
      //     "C",
      //     "d",
      //     "e",
      //     "f",
      //     "g",
      //     "h",
      //     "i",
      //     "j",
      //     "k"
      //   ],
      //   "edges": [
      //     [0, 1],
      //     [1, 2],
      //     [2, 3],
      //     [3, 4],
      //     [4, 5],
      //     [5, 6],
      //     [6, 7],
      //     [7, 9],
      //     [9, 10]
      //   ],
      //   "dependentsCount": [
      //     37000,
      //     15000,
      //     39500,
      //     28500,
      //     16500,
      //     29500,
      //     17500,
      //     23500,
      //     15500,
      //     13500,
      //     29500
      //   ]
      // };


      // var nodes = data.nodes.map(function (nodeName, idx) {
      //   return {
      //     name: nodeName,
      //     value: data.dependentsCount[idx],
      //   };
      // });

      // var edges = data.edges.map(function (edge) {
      //   return {
      //     source: data.nodes[edge[0]], // 使用节点名称作为边的起点
      //     target: data.nodes[edge[1]], // 使用节点名称作为边的终点
      //   };
      // });

      // nodes.forEach(function (node) {
      //   node.emphasis = {
      //     label: {
      //       show: true,
      //     },
      //   };
      //   if (node.value > 1000) {
      //     node.label = {
      //       show: true,
      //     };
      //   }
      // });

      // var option = {
      //   backgroundColor: "transparent",
      //   series: [{
      //     type: "graphGL",
      //     // layout: 'radial', 
      //     nodes: nodes,
      //     edges: edges,
      //     radius: '55%', // 设置半径来控制节点和边的分布范围  
      //     center: ['50%', '50%'], // 设置放射状布局的中心点位置
      //     modularity: {
      //       resolution: 2,
      //       sort: true,
      //     },
      //     lineStyle: {
      //       color: "rgba(255,255,255,1)",
      //       opacity: 0.5,
      //     },
      //     itemStyle: {
      //       opacity: 1,
      //       color: function (params) {
      //         let num = myColor.length;
      //         return myColor[params.dataIndex % num];
      //       },
      //     },
      //     focusNodeAdjacency: false,
      //     focusNodeAdjacencyOn: "click",
      //     symbolSize: function (value) {
      //       return Math.sqrt(value / 10);
      //     },
      //     label: {
      //       color: "#fff",
      //       poisition: 'center'
      //     },
      //     emphasis: {
      //       label: {
      //         show: true,
      //       },
      //       lineStyle: {
      //         opacity: 0.6,
      //         width: 4
      //       }
      //     },
      //     // forceAtlas2: {
      //     //   steps: 8,
      //     //   stopThreshold: 1,
      //     //   gravity: 0.8,
      //     //   edgeWeight: 0.2,
      //     //   jitterTolerence: 0.1,
      //     //   edgeWeightInfluence: 0.1,
      //     //   scalingRatio: 25,
      //     //   workerEnabled: true,
      //     // },
      //   }],
      // };
      let nodes = [
        {
          name: "汽车产业",
          value: "1200",
          id: "1",
          x: '800',
          y: '800',
          symbolSize: 60,//节点大小
          symbol: 'circle',//节点形状，'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'也可'image://url'设置节点图片
        },
        {
          name: "平台运营",
          value: "500",
          id: "2",
          symbolSize: 110,//节点大小
          symbol: 'circle',
        },
        {
          name: "协议信息",
          value: "1200",
          id: "3",
          symbolSize: 120,//节点大小
          symbol: 'circle',
        },
        {
          name: "智能创造",
          value: "1200",
          id: "4",
          symbolSize: 100,//节点大小
          symbol: 'circle',
        },
        {
          name: "外部数据",
          value: "1200",
          id: "5",
          symbolSize: 60,//节点大小
          symbol: 'circle',
        },
        {
          name: "通信",
          value: "1200",
          id: "6",
          symbolSize: 30,//节点大小
          symbol: 'circle',
        },
        {
          name: "研发数据",
          value: "1200",
          id: "7",
          symbolSize: 60,//节点大小
          symbol: 'circle',
        },
        {
          name: "管理数据",
          value: "1200",
          id: "8",
          symbolSize: 120,//节点大小
          symbol: 'circle',
        },
        {
          name: "库存数据",
          value: "1200",
          id: "9",
          symbolSize: 90,//节点大小
          symbol: 'circle',
        },
        {
          name: "采购数据",
          value: "1200",
          id: "10",
          symbolSize: 80,//节点大小
          symbol: 'circle',
        },
        {
          name: "辅助信息",
          value: "1200",
          id: "11",
          symbolSize: 30,//节点大小
          symbol: 'circle',
        },
        {
          name: "辅助信息",
          value: "1200",
          id: "12",
          symbolSize: 30,//节点大小
          symbol: 'circle',
        },

        {
          name: "辅助信息",
          value: "1200",
          id: "13",
          symbolSize: 30,//节点大小
          symbol: 'circle',
        },

        {
          name: "库存数据",
          value: "1200",
          id: "14",
          symbolSize: 90,//节点大小
          symbol: 'circle',
        },

        {
          name: "采购数据",
          value: "1200",
          id: "15",
          symbolSize: 80,//节点大小
          symbol: 'circle',
        },


        {
          name: "辅助信息",
          value: "1200",
          id: "16",
          symbolSize: 30,//节点大小
          symbol: 'circle',
        },

        {
          name: "库存数据",
          value: "1200",
          id: "17",
          symbolSize: 90,//节点大小
          symbol: 'circle',
        },

        {
          name: "辅助信息",
          value: "1200",
          id: "18",
          symbolSize: 40,//节点大小
          symbol: 'circle',
        },

        {
          name: "辅助信息",
          value: "900",
          id: "19",
          symbolSize: 60,//节点大小
          symbol: 'circle',
        },

        {
          name: "库存数据",
          value: "800",
          id: "20",
          symbolSize: 90,//节点大小
          symbol: 'circle',
        },

        {
          name: "采购数据",
          value: "1000",
          id: "21",
          symbolSize: 80,//节点大小
          symbol: 'circle',
        },


        {
          name: "辅助信息",
          value: "200",
          id: "22",
          symbolSize: 50,//节点大小
          symbol: 'circle',
        },

        {
          name: "库存数据",
          value: "1100",
          id: "23",
          symbolSize: 90,//节点大小
          symbol: 'circle',
        },

        {
          name: "汽车产业",
          value: "1200",
          id: "24",
          x: '800',
          y: '800',
          symbolSize: 90,//节点大小
          symbol: 'circle',//节点形状，'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow', 'none'也可'image://url'设置节点图片
        },
        {
          name: "平台运营",
          value: "500",
          id: "25",
          symbolSize: 120,//节点大小
          symbol: 'circle',
        },
        {
          name: "协议信息",
          value: "1200",
          id: "26",
          symbolSize: 90,//节点大小
          symbol: 'circle',
        },
        {
          name: "智能创造",
          value: "100",
          id: "27",
          symbolSize: 100,//节点大小
          symbol: 'circle',
        },
        {
          name: "外部数据",
          value: "600",
          id: "28",
          symbolSize: 60,//节点大小
          symbol: 'circle',
        },
        {
          name: "通信",
          value: "1200",
          id: "29",
          symbolSize: 50,//节点大小
          symbol: 'circle',
        },
        {
          name: "研发数据",
          value: "500",
          id: "30",
          symbolSize: 80,//节点大小
          symbol: 'circle',
        },

      ];
      let links = [
        {
          source: "1",
          target: "2",
          relation: {
            name: "",
            id: "1",
          },
        },
        {
          source: "1",
          target: "3",
          relation: {
            name: "",
            id: "1",
          },
        },
        {
          source: "1",
          target: "4",
          relation: {
            name: "",
            id: "1",
          },
        },
        {
          source: "4",
          target: "1",
          relation: {
            name: "",
            id: "1",
          },
        },
        {
          source: "11",
          target: "5",
          relation: {
            name: "",
            id: "1",
          },
        },
        {
          source: "3",
          target: "5",
          relation: {
            name: "",
            id: "1",
          },
        },
        {
          source: "8",
          target: "5",
          relation: {
            name: "",
            id: "1",
          },
        },
        {
          source: "3",
          target: "7",
          relation: {
            name: "",
            id: "1",
          },
        },
      ];
      var option = {

        series: [{
          tooltip: {},
          type: 'graphGL',
          center: ["50%", 2], // 表示在视图中心
          zoom: 0.6,
          // layout: 'none',
          // 设置节点数据
          nodes: nodes,
          links: links,
          left: '50%',
          right: '0',
          top: '0%',
          bottom: '2%',
          roam: true,
          // 设置边数据
          itemStyle: {
            opacity: 1,
            color: function (params) {
              let num = myColor.length;
              return myColor[params.dataIndex % num];
            },
          },
          label: {
            show: true,
            position: 'top',
            distance: -80,
            align: 'center',
            verticalAlign: 'middle',
            color: '#fff',
            fontSize: 16,
            lineHeight: 22,
            formatter: function (params) {
              return params.data.name + '\n' + params.data.value;
            }
          },
          autoCurveness: 0.01, //多条边的时候，自动计算曲率
          edgeLabel: {//边的设置
            show: true,
            position: "middle",
            fontSize: 12,
            formatter: (params) => {
              return params.data.relation.name;
            },
          },
          forceAtlas2: {
            // initLayout: 'circular',
            steps: 10,  // 迭代步数
            // stopThreshold: 10,  // 停止迭代的阈值
            edgeWeight: 0.001,  // 边权重
            // jitterTolerence:10,  // 抖动容忍度
            // edgeWeightInfluence: 10,  // 边权重影响因子
            scalingRatio: 10,  // 缩放比例
            workerEnabled: false,  // 是否启用Web Worker
            edgeLength: 10,
            repulsion: 50,
            gravity: 0.001,  // 引力因子
          }
        }]
      };

      let myColor = ["#6D8FE1", "#E5874E", "#187F9A", "#BA7FDD", "#5ED4C2", "#6D8FE1", "#E5874E", "#187F9A", "#BA7FDD", "#5ED4C2", "#6D8FE1", "#E5874E", "#187F9A", "#BA7FDD", "#5ED4C2", "#6D8FE1", "#E5874E", "#187F9A", "#BA7FDD", "#5ED4C2", "#6D8FE1", "#E5874E", "#187F9A", "#BA7FDD", "#5ED4C2", "#6D8FE1", "#E5874E", "#187F9A", "#BA7FDD", "#5ED4C2", "#6D8FE1", "#E5874E", "#187F9A", "#BA7FDD", "#5ED4C2", "#6D8FE1", "#E5874E", "#187F9A", "#BA7FDD", "#5ED4C2", "#6D8FE1", "#E5874E", "#187F9A", "#BA7FDD", "#5ED4C2", "#6D8FE1", "#E5874E", "#187F9A", "#BA7FDD", "#5ED4C2", "#6D8FE1", "#E5874E", "#187F9A", "#BA7FDD", "#5ED4C2", "#6D8FE1", "#E5874E", "#187F9A", "#BA7FDD", "#5ED4C2",];

      this.myChart2.setOption(option);
    },
    handleResize() {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.myChart1.resize();
        this.myChart.resize();
        // this.getfu()
        // this.myChart2.resize();
      }, 200);
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 0 1%;
  background: url(../../img/bg.png) center no-repeat;
  background-size: 100% 100%;

  .headerbar {
    margin-bottom: calc(100vh * 20 / 1080);
    width: 100%;
    height: calc(100vh * 100 / 1080);
    background: url(../../img/head_bg.png) center no-repeat;
    background-size: 100% 100%;
    position: relative;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      position: absolute;
      left: 0;
      z-index: 999;
      width: auto;
      height: auto;
      padding-left: 12px;

      img {
        width: auto;
        max-height: 40px;
        margin-right: 12px;
      }
    }

    .zhongjian {
      width: auto;
      height: auto;
      text-align: center;

      p {
        font-size: 2em;
        color: #fff;
      }
    }
  }

  .xuanze {
    position: relative;
    z-index: 99999;
    margin-bottom: calc(100vh * 20 / 1080);
    width: 100%;
    height: calc(100vh * 80 / 1080);
    background: url(../../img/zhong_bg.png) center no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 1%;

    .xz1 {
      width: 18%;
      height: 44px;
      display: flex;
      align-items: center;

      p {
        font-size: 16px;
        color: #fff;
      }

      .zlist {
        margin-left: 15px;
        width: 70%;
        height: 100%;
        background: #0B3F87;
        cursor: pointer;

        .el-select {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          background: #0B3F87;
          border-radius: 0;

          .el-input__inner {
            font-size: 14px;
            color: #A1B5D1;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            i {
              width: 44px;
              height: 44px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background: #032E67;
              font-size: 40px;
            }
          }
        }
      }
    }

    .xz2 {
      width: 44px;
      height: 44px;
      background: #0B3F87;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: pointer;

      img {
        width: auto;
      }
    }
  }

  .content {
    position: relative;
    z-index: 888;
    width: 100%;
    height: calc(100vh * 860 / 1080);
    display: flex;
    justify-content: space-between;

    .left1Top {
      width: 100%;
      height: calc(100vh * 40 / 1080);
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: url(../../img/top_bg.png) center no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 0 3%;

      .left1Top1 {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;

        img {
          width: auto;
          margin-right: 10px;
        }

        p {
          font-size: 1.2rem !important;
          color: #fff;
        }
      }

      .more {
        width: auto;

        img {
          width: auto;
        }
      }
    }

    @keyframes floatUpDown {
      0% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-20px);
        /* 上浮的距离 */
      }

      100% {
        transform: translateY(0);
      }
    }

    .conLeft {
      position: relative;
      z-index: 9999;
      width: 21%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding-bottom: 1%;

      .left1 {
        margin-bottom: calc(100vh * 10 / 1080);
        width: 100%;
        height: calc(100vh * 126 / 1080);

        .left1Top {
          width: 100%;
          height: calc(100vh * 40 / 1080);
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: url(../../img/top_bg.png) center no-repeat;
          background-size: 100% 100%;
          box-sizing: border-box;
          padding: 0 3%;

          .left1Top1 {
            width: auto;
            height: auto;
            display: flex;
            align-items: center;

            img {
              width: auto;
              margin-right: 10px;
            }

            p {
              font-size: 0.8em;
              color: #fff;
            }
          }

          .more {
            width: auto;

            img {
              width: auto;
            }
          }
        }

        .left1Con {
          width: 100%;
          height: calc(100vh * 86 / 1080);
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 3%;
          background: #041736;
          background: url(../../img/bg1.png) center no-repeat;
          background-size: 100% 100%;

          .left1Con1 {
            width: 46%;
            height: auto;
            display: flex;
            align-items: center;
            border-right: 2px solid #fff;

            img {
              width: auto;
              margin-right: 15px;
              animation: floatUpDown 2.5s ease-in-out infinite;
            }

            p {
              font-size: 45px;
              color: #fff;
            }
          }

          .left1Con2 {
            width: 45%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .list1 {
              margin: 5px 0;
              width: 100%;
              height: auto;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              &:nth-child(2) {
                span {
                  background: #168FFF;
                }
              }

              span {
                margin-right: 5px;
                width: 60px;
                height: 20px;
                background: #FF9813;
                border-radius: 2px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                color: #fff;
              }

              p {
                font-size: 15px;
                color: #fff;
              }
            }
          }
        }
      }

      .left2 {
        margin-bottom: calc(100vh * 10 / 1080);
        width: 100%;
        height: calc(100vh * 270 / 1080);

        .left1Top {
          width: 100%;
          height: calc(100vh * 40 / 1080);
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: url(../../img/top_bg.png) center no-repeat;
          background-size: 100% 100%;
          box-sizing: border-box;
          padding: 0 3%;

          .left1Top1 {
            width: auto;
            height: auto;
            display: flex;
            align-items: center;

            img {
              width: auto;
              margin-right: 10px;
            }

            p {
              font-size: 0.8em;
              color: #fff;
            }
          }

          .more {
            width: auto;

            img {
              width: auto;
            }
          }
        }

        .left2Con {
          width: 100%;
          height: calc(100vh * 230 / 1080);
          background: url(../../img/bg2.png) center no-repeat;
          background-size: 100% 100%;

          #echarts {
            width: 100%;
            height: 100%;
          }

          #echarts1 {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .conMid {
      position: relative;
      width: 56%;
      height: 100%;

      .midTop {
        width: 100%;
        height: 48px;
        position: relative;
        z-index: 8888;
      }

      .midC {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 90%;
        z-index: 9;

        .fufu {
          width: 100%;
          height: 100%;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }



    }





    .conRight {
      width: 21%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding-bottom: 1%;
      position: relative;
      z-index: 9999;

      .right1 {
        margin-bottom: calc(100vh * 10 / 1080);
        width: 100%;
        height: calc(100vh * 270 / 1080);

        .left1Top {
          width: 100%;
          height: calc(100vh * 40 / 1080);
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: url(../../img/top_bg.png) center no-repeat;
          background-size: 100% 100%;
          box-sizing: border-box;
          padding: 0 3%;

          .left1Top1 {
            width: auto;
            height: auto;
            display: flex;
            align-items: center;

            img {
              width: auto;
              margin-right: 10px;
            }

            p {
              font-size: 0.8em;
              color: #fff;
            }
          }

          .more {
            width: auto;

            img {
              width: auto;
            }
          }
        }

        .right1Con {
          width: 100%;
          height: calc(100vh * 230 / 1080);
          box-sizing: border-box;
          padding: 5%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          background: url(../../img/bg2.png) center no-repeat;
          background-size: 100% 100%;

          .list1 {
            width: 100%;
            height: 24%;
            border-bottom: 1px dotted #78B1FF;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              font-size: 14px;
              color: #fff;
              width: 25%;
              text-align: left;
            }

            span {
              font-size: 16px;
              color: #fff;
              width: 15%;
            }

            .el-progress {
              margin: 0 10px;
              width: 65%;
            }
          }
        }
      }
    }
  }

}
</style>

<style>
.zlist .el-input__inner {
  font-size: 14px;
  color: #A1B5D1;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #0B3F87;
  border: none;
}

.zlist .el-input__suffix {
  width: 44px;
  height: 44px;
  background: #032E67;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.zlist .el-input__icon {
  width: 44px;
  height: 44px;
}

.zlist .el-select__caret {
  font-size: 30px !important;
}

.list1 .el-progress-bar__outer {
  border-radius: 0 !important;
}

.list1 .el-progress-bar__inner {
  border-radius: 0 !important;
}

.midTop .el-input__inner {
  background: #012775;
  border: 1px solid #C0FEFF;
  box-sizing: border-box;
  padding: 3%;
}

.midTop .el-button {
  background: #FA7A55 !important;
  color: #fff;
  border: 1px solid #FA7A55 !important;
}

.midTop .el-input-group__append {
  background: #FA7A55 !important;
  border: 1px solid #FA7A55 !important;
}

.midTop .el-icon-search {
  font-size: 18px;
  color: #fff;
}

.midTop span {
  color: #fff;
  font-size: 15px;
}
</style>