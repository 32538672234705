import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import * as echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import scroll from 'vue-seamless-scroll'


Vue.use(scroll)
Vue.use(ElementUI);

Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
