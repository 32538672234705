<template>
    <div class="container">
        <div class="headerbar">
            <div class="logo">
                <img src="../../img/logo.svg" alt="">
                <img src="../../img/logo2.png" alt="">
            </div>
            <div class="zhongjian">
                <p>数联网数据流通基础设施</p>
            </div>
        </div>
        <div class="content">
            <div class="thirdLeft">
                <div class="left1">
                    <div class="left1Top">
                        <div class="left1Top1">
                            <img src="../../img/biaozhi.png" alt="">
                            <p>三大核心价值</p>
                        </div>
                        <div class="more">
                            <img src="../../img/more.png" alt="">
                        </div>
                    </div>
                    <div class="left1Con">
                        <img src="../../img/sanbg1.svg" alt="">
                    </div>
                </div>
                <div class="left2">
                    <div class="left1Top">
                        <div class="left1Top1">
                            <img src="../../img/biaozhi.png" alt="">
                            <p>三大客户群体</p>
                        </div>
                        <div class="more">
                            <img src="../../img/more.png" alt="">
                        </div>
                    </div>
                    <div class="left2Con">
                        <!-- <div class="left21">
                            <div class="left21_t">
                                <img src="../../img/arrow.png" alt="">
                                <p>数商</p>
                                <img src="../../img/arrow.png" alt="">
                            </div>
                            <div class="left21_m">
                                <p>帮助客户进行数据治理、数据承销，协
助数据资产在交易所上架。</p>
                            </div>
                         </div>    -->
                        <div class="san">
                            <img src="../../img/san1.svg" alt="">
                        </div>
                        <div class="san">
                            <img src="../../img/san2.svg" alt="">
                        </div>
                        <div class="san">
                            <img src="../../img/san3.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="left3">
                    <div class="left1Top">
                        <div class="left1Top1">
                            <img src="../../img/biaozhi.png" alt="">
                            <p>六大产品能力</p>
                        </div>
                        <div class="more">
                            <img src="../../img/more.png" alt="">
                        </div>
                    </div>
                    <div class="left3Con">
                        <div class="list1">
                            <img src="../../img/st1.png" alt="">
                            <p>一点接入</p>
                        </div>
                        <div class="list1">
                            <img src="../../img/st2.png" alt="">
                            <p>智能探查</p>
                        </div>
                        <div class="list1">
                            <img src="../../img/st3.png" alt="">
                            <p>安全管控</p>
                        </div>
                        <div class="list1">
                            <img src="../../img/st4.png" alt="">
                            <p>全网可达</p>
                        </div>
                        <div class="list1">
                            <img src="../../img/st5.png" alt="">
                            <p>数据地图</p>
                        </div>
                        <div class="list1">
                            <img src="../../img/st6.png" alt="">
                            <p>可信交付</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="thirdRight">
                <div class="rightC">
                    <div class="call">
                        <div class="c1">
                            <div class="ctxt">
                                <p>纺织服饰</p>
                            </div>
                            <img class="dh" src="../../img/s1.png" alt="">
                        </div>
                        <div class="c1">
                            <div class="ctxt">
                                <p>汽车智造</p>
                            </div>
                            <img class="dh" src="../../img/s2.png" alt="">
                        </div>
                        <div class="c1">
                            <div class="ctxt">
                                <p>智慧农业</p>
                            </div>
                            <img class="dh" src="../../img/s3.png" alt="">
                        </div>
                        <div class="c1">
                            <div class="ctxt">
                                <p>联合反欺诈</p>
                            </div>
                            <img class="dh" src="../../img/s4.png" alt="">
                        </div>
                        <div class="c1">
                            <div class="ctxt">
                                <p>联合营销</p>
                            </div>
                            <img class="dh" src="../../img/s5.png" alt="">
                        </div>
                        <div class="c1">
                            <div class="ctxt">
                                <p>联合风控</p>
                            </div>
                            <img class="dh" src="../../img/s6.png" alt="">
                        </div>
                    </div>
                    <div class="ms">
                        <div class="ms_left">
                            <p>隐私计算技术</p>
                            <div class="text">
                                <p>安全计算</p>
                            </div>
                        </div>
                        <h1>数据流通服务平台</h1>
                        <div class="ms_left">
                            <p>区块链技术</p>
                            <div class="text">
                                <p>可信流通</p>
                            </div>
                        </div>
                    </div>
                    <div class="mser">
                        <div class="mser_left">
                            <div class="text">
                                <p>数据采集</p>
                            </div>
                            <div class="text">
                                <p>数据储算</p>
                            </div>
                        </div>
                        <div class="mser_mid">
                            <h2>大数据基础服务</h2>
                            <div class="text">
                                <p>数据治理</p>
                            </div>
                        </div>
                        <div class="mser_left">
                            <div class="text">
                                <p>数据开发</p>
                            </div>
                            <div class="text">
                                <p>数据安全</p>
                            </div>
                        </div>
                    </div>
                    <div class="didi">
                        <div class="dd1">
                            <div class="mao">
                                <img src="../../img/mao.png" alt="">
                                <p>数联猫</p>
                            </div>
                            <img class="jiantou" src="../../img/jiantou.svg" alt="">
                            <div class="dd1des">
                                <div class="text">
                                    <p>数据共享服务网络</p>
                                </div>
                                <div class="dd1list">
                                    <div class="list1">
                                        <img src="../../img/c1.png" alt="">
                                        <p>存储</p>
                                    </div>
                                    <div class="list1">
                                        <img src="../../img/c2.png" alt="">
                                        <p>计算</p>
                                    </div>
                                    <div class="list1">
                                        <img src="../../img/c3.png" alt="">
                                        <p>网络</p>
                                    </div>
                                </div>
                            </div>
                            <img class="jiantou fan" src="../../img/jiantou.svg" alt="">
                            <div class="mao mao2">
                                <img src="../../img/mao.png" alt="">
                                <p>数联猫</p>
                            </div>
                        </div>
                        <div class="dd2">
                            <div class="dd21">
                                <img class="dh" src="../../img/s7.png" alt="">
                                <p>国家产业监测中心</p>
                            </div>
                            <div class="dd21">
                                <img class="dh" src="../../img/s8.png" alt="">
                                <p>国家数据监控中心</p>
                            </div>
                            <div class="dd21">
                                <img class="dh" src="../../img/s9.png" alt="">
                                <p>国家级数据流通枢纽</p>
                            </div>
                            <div class="dd21">
                                <img class="dh" src="../../img/s10.png" alt="">
                                <p>国家交易备案中心</p>
                            </div>
                            <div class="dd21">
                                <img class="dh" src="../../img/s11.png" alt="">
                                <p>国家级数据登记中心</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rightR">
                    <div class="wenzi">
                        <p>数据资本</p>
                        <p>数据资产</p>
                        <p>数据产品</p>
                        <p>数据资源</p>
                    </div>
                    <img src="../../img/zhibiao.png" alt="">
                </div>
            </div>
        </div>
        <router-link to="/second" class="zuozuo">
            <img src="../../img/xiangzuo.png" alt="">
        </router-link>
        <router-link to="/fouth" class="youyou">
            <img src="../../img/xiangyou.png" alt="">
        </router-link>
    </div>
</template>

<script>
import 'animate.css'
export default {

}
</script>

<style scoped lang="scss">
.container {
    position: relative;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 0 1%;
    background: url(../../img/bg.png) center no-repeat;
    background-size: 100% 100%;

    .headerbar {
        margin-bottom: calc(100vh * 20 / 1080);
        width: 100%;
        height: calc(100vh * 100 / 1080);
        background: url(../../img/head_bg.png) center no-repeat;
        background-size: 100% 100%;
        position: relative;
        z-index: 99999;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo {
      position: absolute;
      left: 0;
      z-index: 999;
      width: auto;
      height: auto;
      padding-left: 12px;

      img {
        width: auto;
        max-height: 40px;
        margin-right: 12px;
      }
    }

        .zhongjian {
            width: auto;
            height: auto;
            text-align: center;

            p {
                font-size: 2em;
                color: #fff;
            }
        }
    }

    .content {
        position: relative;
        width: 100%;
        height: calc(100vh * 960 / 1080);
        display: flex;
        justify-content: space-between;

        .left1Top {
            width: 100%;
            height: calc(100vh * 40 / 1080);
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: url(../../img/top_bg.png) center no-repeat;
            background-size: 100% 100%;
            box-sizing: border-box;
            padding: 0 3%;

            .left1Top1 {
                width: auto;
                height: auto;
                display: flex;
                align-items: center;

                img {
                    width: auto;
                    margin-right: 10px;
                }

                p {
                    font-size: 1.2rem;
                    color: #fff;
                }
            }

            .more {
                width: auto;

                img {
                    width: auto;
                }
            }
        }

        .thirdLeft {
            position: relative;
            z-index: 9999;
            width: 42%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
            padding-bottom: 1%;

            .left1 {
                margin-bottom: calc(100vh * 10 / 1080);
                width: 100%;
                height: calc(100vh * 310 / 1080);

                .left1Con {
                    width: 100%;
                    height: calc(100vh * 260 / 1080);
                    box-sizing: border-box;
                    padding: 0 6%;
                    background: #051A3E;

                    img {
                        width: 98%;
                        height: 98%;
                        // object-fit: contain;
                    }
                }
            }

            .left2 {
                margin-bottom: calc(100vh * 10 / 1080);
                width: 100%;
                height: calc(100vh * 380 / 1080);

                .left2Con {
                    width: 100%;
                    height: calc(100vh * 330 / 1080);
                    box-sizing: border-box;
                    padding: 2%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: #051A3E;

                    .san {
                        width: 31%;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }

            .left3 {
                width: 100%;
                height: calc(100vh * 250 / 1080);

                .left3Con {
                    width: 100%;
                    height: calc(100vh * 200 / 1080);
                    box-sizing: border-box;
                    padding: 2% 3%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background: #0A1539;
                    flex-wrap: wrap;

                    .list1 {
                        margin-bottom: calc(100vh * 15 / 1080);
                        width: 30%;
                        height: calc(100vh * 82 / 1080);
                        background: linear-gradient(to right, #0F3886, #071D58);
                        box-sizing: border-box;
                        padding: 2%;
                        display: flex;
                        align-items: center;
                        border-radius: 5px;

                        img {
                            margin-right: 10px;
                            width: auto;
                        }

                        p {
                            font-size: 1rem;
                            color: #fff;
                        }
                    }
                }
            }
        }

        .thirdRight {
            position: relative;
            z-index: 9999;
            width: 55%;
            height: 100%;
            display: flex;
            box-sizing: border-box;
            padding-bottom: 1%;

            .rightC {
                position: relative;
                margin: auto;
                width: 90%;
                height: 100%;

                // display: flex;
                // flex-direction: column;
                // align-items: center;
                @keyframes floatUpDown {
                    0% {
                        transform: translateY(0);
                    }

                    50% {
                        transform: translateY(-20px);
                        /* 上浮的距离 */
                    }

                    100% {
                        transform: translateY(0);
                    }
                }

                .call {
                    margin-top: calc(100vh * 50 / 1080);
                    position: relative;
                    width: 80%;
                    height: calc(100vh * 300 / 1080);
                    position: relative;
                    background: url(../../img/yuan1.png) center no-repeat;
                    background-size: 100% 100%;
                    display: flex;



                    .c1 {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 999;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 126px;

                        &:nth-child(2) {
                            top: 35%;
                            left: 25%;
                            z-index: 999;

                            img {
                                width: auto;
                                animation: floatUpDown 2.5s ease-in-out infinite;
                            }
                        }

                        &:nth-child(3) {
                            top: 35%;
                            left: 55%;
                            z-index: 999;

                            img {
                                width: auto;
                                animation: floatUpDown 3s ease-in-out infinite;
                            }
                        }

                        &:nth-child(4) {
                            top: 0%;
                            left: 85%;
                            z-index: 999;

                            img {
                                width: auto;
                                animation: floatUpDown 2s ease-in-out infinite;
                            }
                        }

                        &:nth-child(5) {
                            top: -10%;
                            left: 55%;
                            z-index: 999;

                            img {
                                width: auto;
                                animation: floatUpDown 2.5s ease-in-out infinite;
                            }
                        }

                        &:nth-child(6) {
                            top: -10%;
                            left: 25%;
                            z-index: 999;

                            img {
                                width: auto;
                                animation: floatUpDown 3s ease-in-out infinite;
                            }
                        }

                        .ctxt {
                            margin-bottom: 15px;
                            width: 100%;
                            height: 26px;
                            background: #062B4B;
                            border-left: 2px solid #58F9F5;
                            border-right: 2px solid #58F9F5;

                            p {
                                font-size: 0.8rem;
                                color: #F9AB33;
                                text-align: center;
                                line-height: 26px;
                            }
                        }

                        img {
                            width: auto;
                            animation: floatUpDown 2s ease-in-out infinite;
                        }
                    }
                }

                .ms {
                    position: relative;
                    margin-top: -5%;
                    margin-bottom: 2%;
                    width: 80%;
                    height: calc(100vh * 160 / 1080);
                    background: linear-gradient(to right, #081E5F, #1B3B9E, #081E5F);
                    box-sizing: border-box;
                    padding: 3%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    h1 {
                        display: block;
                        margin: 0 8%;
                        font-size: 30px;
                        color: #F9AB33;
                    }

                    .ms_left {
                        width: 20%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        p {
                            font-size: 22px;
                            color: #fff;
                        }

                        .text {
                            margin-top: 15%;
                            width: 185px;
                            height: 46px;
                            background: url(../../img/sanbg3.png) center no-repeat;
                            background-size: 100% 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .mser {
                    position: relative;
                    margin-bottom: 5%;
                    width: 80%;
                    height: calc(100vh * 160 / 1080);
                    background: linear-gradient(to right, #081E5F, #1B3B9E, #081E5F);
                    box-sizing: border-box;
                    padding: 3%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .mser_left {
                        width: 20%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        p {
                            font-size: 22px;
                            color: #fff;
                        }

                        .text {
                            margin-top: 15%;
                            width: 185px;
                            height: 46px;
                            background: url(../../img/sanbg3.png) center no-repeat;
                            background-size: 100% 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &:first-child {
                                margin: 0;
                            }
                        }
                    }

                    .mser_mid {
                        width: 47%;
                        height: auto;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        h2 {
                            display: block;
                            margin: 0 8%;
                            font-size: 30px;
                            color: #F9AB33;

                        }

                        .text {
                            margin-top: 3%;
                            width: 185px;
                            height: 46px;
                            background: url(../../img/sanbg3.png) center no-repeat;
                            background-size: 100% 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            p {
                                font-size: 22px;
                                color: #fff;
                            }
                        }
                    }
                }

                .didi {
                    position: relative;
                    margin-top: -5%;
                    width: 84%;
                    height: calc(100vh * 300 / 1080);
                    position: relative;
                    background: url(../../img/yuan2.png) center no-repeat;
                    background-size: 100% 100%;

                    .dd1 {
                        display: flex;
                        width: 100%;
                        height: calc(100vh * 150 / 1080);
                        align-items: center;
                        justify-content: center;
                        .mao2{
                            margin-left: 2%;
                            margin-right: 0;
                        }
                        .mao {
                            margin-bottom: 8%;
                            margin-right: 2%;
                            width: auto;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            img {
                                width: auto;
                            }

                            p {
                                font-size: 1rem;
                                color: #58F9F5;
                            }
                        }

                        .jiantou{
                            margin-bottom: 8%;
                            width: auto;
                            &.fan{
                                transform: rotate(180deg);
                            }
                        }

                        span {
                            width: 22%;
                            height: 1px;
                            background: #58F9F5;
                        }

                        .dd1des {
                            width: 35%;
                            height: calc(100vh * 150 / 1080);
                            background: url(../../img/sanbg2.png) center no-repeat;
                            background-size: 100% 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            .text {
                                margin-bottom: 2%;
                                width: 80%;
                                height: calc(100vh * 30 / 1080);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background: #348EB1;

                                p {
                                    font-size: 0.8rem;
                                    color: #fff;
                                }
                            }

                            .dd1list {
                                width: 90%;
                                height: auto;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .list1 {
                                    width: 30%;
                                    height: auto;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;

                                    img {
                                        width: auto;
                                    }

                                    p {
                                        font-size: 0.8rem;
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }

                    .dd2 {
                        margin-top: -13%;
                        width: 100%;
                        height: auto;
                        display: flex;
                        justify-content: center;

                        .dd21 {
                            display: flex;
                            flex-direction: column;
                            width: auto;
                            align-items: center;
                            justify-content: center;

                            &:nth-child(2) {
                                margin-left: 5%;
                                margin-top: 8%;
                                img {
                                width: auto;
                                animation: floatUpDown 2.5s ease-in-out infinite;
                            }
                            }

                            &:nth-child(3) {
                                margin-top: 15%;
                                img {
                                width: auto;
                                animation: floatUpDown 3s ease-in-out infinite;
                            }
                            }

                            &:nth-child(4) {
                                margin-right: 5%;
                                margin-top: 8%;
                                img {
                                width: auto;
                                animation: floatUpDown 2.5s ease-in-out infinite;
                            }
                            }

                            img {
                                width: auto;
                                animation: floatUpDown 2s ease-in-out infinite;
                            }

                            p {
                                font-size: 0.9rem;
                                color: #F9AB33;
                            }
                        }
                    }
                }
            }

            .rightR {
                position: absolute;
                left: 80%;
                z-index: 9999;
                width: 18%;
                height: auto;

                .wenzi {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 999;
                    width: auto;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    padding-top: 50%;
                    padding-bottom: 40%;
                    box-sizing: border-box;
                    justify-content: space-between;
                    padding-left: 26%;

                    p {
                        display: block;
                        font-size: 1.7rem;
                        color: #fff;
                        transform: rotate(-30deg);
                        // transform: skew(-30deg);
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
</style>